<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <!-- <div class="dashboard__container--header eventsHeader" style="padding:0;">

        <h1 v-if="isLogin">Login</h1>

        <span class="flex align-center">

          <span>
            
            <router-link :to="{name: 'loginNew'}">
              <button class="btn ml-4 mb-5 mt-2" v-bind:class="{ 'btn__dark': isLogin, 'btn__outlined': !isLogin }">Login</button>
            </router-link>

          </span>

        </span>
      </div> -->
      <router-view :key="$route.params.id" />

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'authHome',
  data: () => ({
    performingRequest: false,
  }),
  computed: {
    ...mapState(['userProfile', 'currentUser']),
    // isMyGroups() {
    //   return this.$route.name == 'myGroups';
    // },
    // isNewGroups() {
    //   return this.$route.name == 'newGroups';
    // },
    isLogin() {
      return this.$route.name == 'loginNew';
    },
    // isMyShifts() {
    //   return this.$route.name == 'myShifts';
    // },
    // isShift() {
    //   return this.$route.name == 'myShift';
    // },
  },
  // created () {
  //   this.$store.dispatch("getAvailableShifts")
  //   this.$store.dispatch("getMyShifts")
  // },
  methods: {
     goBack() {
      router.go(-1)
    },
  },
  // beforeDestroy () {
  //   this.$store.dispatch('clearShiftLists')
  // }
}
</script>